<template>
  <label class="toggleSwitch">
    <input
      class="toggleSwitch-checkbox"
      :disabled="disabled"
      :name="name"
      type="checkbox"
      :checked="checked === true ? true : false"
      @change="$emit('change', $event)"
    />
    <div class="toggleSwitch-switch"></div>
    <span :class="`toggleSwitch-label${changeLabelPosition ? '--left' : ''}`" v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    checked: Boolean,
    label: String,
    name: String,
    disabled: Boolean,
    changeLabelPosition: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    checked(newValue) {
      this.checked = newValue;
    },
  },
};
</script>

<style lang="scss">
$green: #56c080;
.toggleSwitch {
  cursor: pointer;
  display: flex;
}
.toggleSwitch-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 54px;
  max-width: 54px;
  height: 28px;
  max-height: 28px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  &:before,
  &:after {
    content: "";
  }
  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .toggleSwitch:hover &:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggleSwitch-checkbox:checked + & {
    background: $primary;
    &:before {
      left: 30px;
    }
  }
}
.toggleSwitch-checkbox {
  position: absolute;
  visibility: hidden;
}
.toggleSwitch-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
  &--left {
    order: -1;
    margin-left: 0px;
    margin-right: 5px;
  }
}
</style>
